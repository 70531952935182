<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="ListIcon"
                    width="24"
                    height="24"
                  />
                  <h2>Network specifications</h2>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Vlan Name"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      :rules="form.is_default ? 'max:255' : 'required|max:255'"
                    >
                      <b-form-input
                        v-model="form.name"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter vlan name"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Vlan ID No"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Vlan ID"
                      vid="vlan_id"
                      :rules="form.is_default ? 'max:255' : 'required|max:255|between:2,4094'"
                    >
                      <b-form-input
                        v-model="form.vlan_id"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter vlan ID number"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Interface Address"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Interface Address"
                      vid="interface_ip"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Enter interface address"
                        autocomplete="off"
                        :onchange="(value) => form.interface_ip = value"
                        :model="form.interface_ip"
                        inputs-id="interface_ip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Subnet Mask"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Subnet Mask"
                      vid="subnet_mask"
                    >
                      <b-form-ip
                        :errors="errors"
                        placeholder="Enter subnet address"
                        autocomplete="off"
                        :onchange="(value) => form.subnet_mask = value"
                        :model="form.subnet_mask"
                        inputs-id="subnet_mask"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <div
                  v-if="!(form.is_default && !form.comment)"
                  class="neighbouring-form-container"
                >
                  <b-form-group
                    label="Comment"
                    label-class="label-class"
                    class="neighbouring-form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="comment"
                      rules="max:255"
                    >
                      <b-form-input
                        v-model="form.comment"
                        :disabled="form.is_default"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter your comment"
                        autocomplete="off"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </b-overlay>
            <b-overlay
              :show="loading"
              rounded
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
            >
              <div class="section-block mb-4">
                <div class="section-block__title">
                  <feather-icon
                    icon="GlobeIcon"
                    width="24"
                    height="24"
                  />
                  <h2>{{ form.is_default ? 'Vlan Ports' : 'Ports' }}</h2>
                </div>
                <b-form-group
                  label="Hardware"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="select_hardware"
                  >
                    <v-select
                      v-model="activeSelectedHardware"
                      :options="portsSlots"
                      placeholder="Selected hardware"
                      :reduce="option => option.id"
                      label="name"
                      :disabled="form.is_default"
                      @search="handleChangeHardware($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Tagged ports"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tagged_ports"
                  >
                    <multiselect
                      v-model="tagged_ports"
                      :options="portsOption"
                      :disabled="form.is_default"
                      multiple
                      placeholder="Select tagged ports"
                      class="multiselect with-remove"
                      :searchable="false"
                      label="fullName"
                      @select="(prop) => onSelectPort(prop, 'tagged')"
                      @remove="(prop) => onDeselectPort(prop, 'tagged')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="all-buttons-container">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    :disabled="form.is_default"
                    @click="handleAttachAvailableTo('tagged')"
                  >
                    Select All
                  </b-button>
                  <b-button
                    v-if="tagged_ports.length > 0"
                    type="button"
                    :disabled="form.is_default"
                    @click="onDeselectPortArray('tagged')"
                  >
                    Deselect All
                  </b-button>
                </div>
                <b-form-group
                  label="Untagged ports"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="untagged_ports"
                  >
                    <multiselect
                      v-model="untagged_ports"
                      :options="portsOption"
                      :disabled="form.is_default"
                      multiple
                      placeholder="Select tagged ports"
                      class="multiselect with-remove"
                      :searchable="false"
                      label="fullName"
                      @select="(prop) => onSelectPort(prop, 'untagged')"
                      @remove="(prop) => onDeselectPort(prop, 'untagged')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="all-buttons-container">
                  <b-button
                    type="button"
                    :disabled="form.is_default"
                    @click="handleAttachAvailableTo('untagged')"
                  >
                    Select All
                  </b-button>
                  <b-button
                    v-if="untagged_ports.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    :disabled="form.is_default"
                    @click="onDeselectPortArray('untagged')"
                  >
                    Deselect All
                  </b-button>
                </div>
                <p
                  v-if="form.is_default"
                  class="lan-error-message"
                >
                  Edit is not allowed for dynamic ports
                </p>
              </div>
            </b-overlay>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Update"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="savedLan"
      ref="savedLan"
      title="Success!"
      size="md"
      ok-title="Ok"
      ok-only
      centered
      @hidden="goBack"
      @ok="goBack"
    >
      <p>Your LAN is successfully updated.</p>
      <p>Please note: you might want to update your{{ ' ' }}
        <b-link
          :to="`/site/${siteId}/hardware/index?tab=2&subTab=dhcp_table`"
        >
          DHCP servers
        </b-link> as well</p>
    </b-modal>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BLink,
  BOverlay,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BLink,
    BFormInput,
    BFormIp,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    Multiselect,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      networkId: this.$route.params.networkId,
      form: {
        name: '',
        vlan_id: '',
        interface_ip: '',
        subnet_mask: '',
        comment: '',
      },
      portsOption: [],
      portsOrigin: [],

      tagged_ports: [], // should get its value on current active hardware when a all_tagged_ports is changed (no direct mutation)
      untagged_ports: [], // should get its value on current active hardware when a all_untagged_ports is changed (no direct mutation)

      all_tagged_ports: {}, // {[id_hardware]: [ports]}
      all_untagged_ports: {}, // {[id_hardware]: [ports]}

      portsSlots: [],
      activeSelectedHardware: null,

      loading: true,

      required,
      max,
    }
  },
  watch: {
    activeSelectedHardware() {
      // get ports options from the selected hardware
      this.portsOption = this.portsSlots.find(hardware => hardware.id === this.activeSelectedHardware).ports

      // update tagged and untagged ports
      this.tagged_ports = this.all_tagged_ports[this.activeSelectedHardware] || []
      this.untagged_ports = this.all_untagged_ports[this.activeSelectedHardware] || []

      // filter portOption from tagged and untagged ports
      this.portsOption = this.portsOption.filter(port => {
        const tagged = this.tagged_ports.find(taggedPort => taggedPort.id === port.id)
        const untagged = this.untagged_ports.find(untaggedPort => untaggedPort.id === port.id)

        return !tagged && !untagged
      })
    },
    all_tagged_ports() {
      this.tagged_ports = this.all_tagged_ports[this.activeSelectedHardware] || []
    },
    all_untagged_ports() {
      this.untagged_ports = this.all_untagged_ports[this.activeSelectedHardware] || []
    },
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  async created() {
    try {
      const { data } = await axios.get(`/site/${this.siteId}/controller`)
      const { controller, switches } = data.data
      const portsSlotsRaw = [{
        name: controller.name,
        id: controller.id,
        ports: controller.model.ports.map(port => ({
          ...port,
          fullName: `${port.interface} - ${port.usage}`,
        })),
        type: 'controller',
      }]

      if (switches) {
        switches.forEach(switchItem => {
          portsSlotsRaw.push({
            name: switchItem.name,
            id: switchItem.id,
            ports: switchItem.model.ports.map(port => ({
              ...port,
              fullName: `${port.interface} - ${port.usage}`,
            })),
            type: 'switch',
          })
        })
      }

      // of every existing hardware create keys for all_tagged_ports and all_untagged_ports
      portsSlotsRaw.forEach(hardware => {
        this.all_tagged_ports = {
          ...this.all_tagged_ports,
          [hardware.id]: [],
        }
        this.all_untagged_ports = {
          ...this.all_untagged_ports,
          [hardware.id]: [],
        }
      })

      this.portsOrigin = portsSlotsRaw[0].ports.flat()
      this.portsOption = portsSlotsRaw[0].ports.flat()
      this.portsSlots = portsSlotsRaw
      // eslint-disable-next-line prefer-destructuring
      this.activeSelectedHardware = portsSlotsRaw[0].id

      this.setSiteName(this.siteId)
    } catch (err) {
      console.warn(err)
    }
    try {
      const { data } = await axios.get(`/site/${this.siteId}/network/setting/lan/${this.networkId}`)
      this.form = {
        ...data.data,
      }

      // tagged_ports and untagged_ports is for controller

      // switches ports array contains keys of the switcher

      if (data.data.switches_ports) {
      // handle switches and controller tagged and untagged ports and update options, get info about ports and controllers from portsOrigin and portsSlots
        Object.keys(data.data.switches_ports).forEach(switchId => {
          const { tagged, untagged } = data.data.switches_ports[switchId]

          this.all_tagged_ports = {
            ...this.all_tagged_ports,
            [switchId]: tagged.map(port => {
              const hardwarePorts = this.portsSlots.find(hardware => hardware.id === Number(switchId))

              if (!hardwarePorts) return null
              return hardwarePorts.ports.find(originPort => originPort.id === port.id)
            }),
          }
          this.all_untagged_ports = {
            ...this.all_untagged_ports,
            [switchId]: untagged.map(port => {
              const hardwarePorts = this.portsSlots.find(hardware => hardware.id === Number(switchId))

              if (!hardwarePorts) return null
              return hardwarePorts.ports.find(originPort => originPort.id === port.id)
            }),
          }
        })
      }

      this.all_tagged_ports = {
        ...this.all_tagged_ports,
        [this.portsSlots[0].id]: data.data.tagged_ports ? data.data.tagged_ports.map(port => {
          const hardwarePorts = this.portsSlots.find(hardware => hardware.id === this.portsSlots[0].id)

          if (!hardwarePorts) return null
          return hardwarePorts.ports.find(originPort => originPort.id === port.id)
        }) : [],
      }

      this.all_untagged_ports = {
        ...this.all_untagged_ports,
        [this.portsSlots[0].id]: data.data.untagged_ports ? data.data.untagged_ports.map(port => {
          const hardwarePorts = this.portsSlots.find(hardware => hardware.id === this.portsSlots[0].id)

          if (!hardwarePorts) return null
          return hardwarePorts.ports.find(originPort => originPort.id === port.id)
        }) : [],
      }

      this.loading = false
    } catch (err) {
      console.warn(err)
    }
  },
  methods: {
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          // create link to the site and put it in the title

          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    handleChangeHardware(hardware) {
      this.portsOption = hardware.ports
    },
    handleAttachAvailableTo(type) {
      // all_ update only available ports
      if (type === 'tagged') {
        this.all_tagged_ports = {
          ...this.all_tagged_ports,
          [this.activeSelectedHardware]: [
            ...this.all_tagged_ports[this.activeSelectedHardware],
            ...this.portsOption,
          ],
        }
      } else {
        this.all_untagged_ports = {
          ...this.all_untagged_ports,
          [this.activeSelectedHardware]: [
            ...this.all_untagged_ports[this.activeSelectedHardware],
            ...this.portsOption,
          ],
        }
      }

      // update options
      this.portsOption = []
    },
    onSelectPort(prop, type = 'tagged') {
      // all_ update
      if (type === 'tagged') {
        if (!this.all_tagged_ports[this.activeSelectedHardware]) {
          this.all_tagged_ports = {
            ...this.all_tagged_ports,
            [this.activeSelectedHardware]: [prop],
          }
        } else {
          this.all_tagged_ports = {
            ...this.all_tagged_ports,
            [this.activeSelectedHardware]: [
              ...this.all_tagged_ports[this.activeSelectedHardware],
              prop,
            ],
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!this.all_untagged_ports[this.activeSelectedHardware]) {
          this.all_untagged_ports = {
            ...this.all_untagged_ports,
            [this.activeSelectedHardware]: [prop],
          }
        } else {
          this.all_untagged_ports = {
            ...this.all_untagged_ports,
            [this.activeSelectedHardware]: [
              ...this.all_untagged_ports[this.activeSelectedHardware],
              prop,
            ],
          }
        }
      }

      // clear from options
      this.portsOption = this.portsOption.filter(port => port.id !== prop.id)
    },
    onDeselectPort(prop, type) {
      // all_ update
      // revert to onSelectPort
      if (type === 'tagged') {
        this.all_tagged_ports = {
          ...this.all_tagged_ports,
          [this.activeSelectedHardware]: this.all_tagged_ports[this.activeSelectedHardware].filter(port => port.id !== prop.id),
        }
      } else {
        this.all_untagged_ports = {
          ...this.all_untagged_ports,
          [this.activeSelectedHardware]: this.all_untagged_ports[this.activeSelectedHardware].filter(port => port.id !== prop.id),
        }
      }

      // return to options
      this.portsOption = [
        ...this.portsOption,
        prop,
      ]
    },
    onDeselectPortArray(type) {
      // all_ update
      if (type === 'tagged') {
        this.all_tagged_ports = {
          ...this.all_tagged_ports,
          [this.activeSelectedHardware]: [],
        }
      } else {
        this.all_untagged_ports = {
          ...this.all_untagged_ports,
          [this.activeSelectedHardware]: [],
        }
      }
      // update options
      this.portsOption = [
        ...this.portsOption,
        ...(type === 'tagged' ? this.tagged_ports : this.untagged_ports),
      ]
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const controllerPorts = {
            tagged: this.all_tagged_ports[this.portsSlots[0].id].map(port => port.id),
            untagged: this.all_untagged_ports[this.portsSlots[0].id].map(port => port.id),
          }

          const switchesPorts = this.portsSlots.slice(1).reduce((acc, switchItem) => {
            acc[switchItem.id] = {
              tagged: this.all_tagged_ports[switchItem.id].map(port => port.id),
              untagged: this.all_untagged_ports[switchItem.id].map(port => port.id),
            }

            return acc
          }, {})
          const payload = {
            name: this.form.name,
            vlan_id: this.form.vlan_id,
            interface_ip: this.form.interface_ip,
            subnet_mask: this.form.subnet_mask,
            comment: this.form.comment,
            controller_ports: controllerPorts,
            switches_ports: switchesPorts,
          }
          await axios.put(`site/${this.siteId}/network/setting/lan/${this.networkId}`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$refs.savedLan.show()
        } catch (error) {
          console.warn('error', error)
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            const untaggedPorts = Object.keys(fields).filter(field => field.startsWith('untagged_ports'))
            const taggedPorts = Object.keys(fields).filter(field => field.startsWith('tagged_ports'))

            const errors = {
              ...fields,
              untagged_ports: untaggedPorts.map(port => fields[port])[0],
              tagged_ports: taggedPorts.map(port => fields[port])[0],
            }

            this.$refs.modelsCreate.setErrors(errors)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.lan-error-message {
  font-size: 14px;
  color: rgb(237, 122, 122);
}
</style>
